import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import "swiper/modules/autoplay/autoplay.scss"; // Autoplay module
import styled, { css } from "styled-components/native";
import { spacing_m } from "../../StyleHelpers";
import { useEffect, useMemo, useState } from "react";
import { Dimensions, FlatList, View } from "react-native";
import { routeAggregatePage, routeVamAggregatePage } from "../../Routes";
import CardPackshot from "../CardPackshot";
import SeeMoreComponent from "../SeeMoreComponent";
import { useNavigation } from "@react-navigation/native";
import useGenericContentStore from "../../store/genericContent.store";
import { DraggableScrollView } from "../DraggableScroll";

interface Props {
	id?: any;
	title?: string;
	products?: any;
	isLandscape?: boolean;
	location?: any;
	isProgressBar?: boolean;
	customListId?: string;
	isStudioAccess?: boolean;
	isAssignToScreen?: boolean;
	isSeeMore?: boolean;
	seeMoreLength: number;
	isPreview?: boolean;
	isHome?: boolean;
}
interface StyledProps {
	isLandscape?: boolean;
	isStudioAccess?: boolean;
	index?: number;
	isBackUpImage?: boolean;
}

const StyledSlider = styled.View<StyledProps>`
	margin-right: ${spacing_m};
	position: relative;
	display: flex;
	align-items: flex-start;
	background-color: #2c2c2c;
	border-radius: 16px;
	height: auto;

	${(props) =>
		props.index === 0 &&
		css`
			margin-left: 147px;
		`}

	${(props) =>
		props.isStudioAccess &&
		css`
			margin-bottom: 88px;
		`}
	${(props) =>
		props.isLandscape &&
		props.isBackUpImage &&
		css`
			width: 9.4vw;
			aspect-ratio: 2 / 3;
		`}
		

	${(props) =>
		props.isLandscape &&
		!props.isBackUpImage &&
		css`
			width: 25vw;
			aspect-ratio: 16 / 9;
		`}

	${(props) =>
		!props.isLandscape &&
		css`
			width: 15vw;
			aspect-ratio: 2 / 3;
		`}
`;

const PlaylistSlider = (props: Props) => {
	const navigation = useNavigation();
	const [windowWidth, setWindowWidth] = useState<any>();
	const setAggregateTitle = useGenericContentStore((state: any) => state.setAggregateTitle);

	/**
	 * useEffect to detect resize on window
	 */
	useEffect(() => {
		const resizeHandler = () => {
			setWindowWidth(Dimensions.get("window").width);
		};
		resizeHandler();

		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	}, [windowWidth]);

	const onPressSeeMore = (id: any) => {
		setAggregateTitle(props.title);

		if (props.isStudioAccess) {
			//@ts-ignore
			navigation.navigate(routeVamAggregatePage, { customListId: id });
			return;
		}
		//@ts-ignore
		navigation.navigate(routeAggregatePage, { location: props.location, id });
	};

	const renderItem = ({ item, index }: any) => {
		return (
			//@ts-ignore
			<StyledSlider
				key={`swiper-slide-${item?.parentProductId ?? item?.id}`}
				isLandscape={props.isLandscape}
				isBackUpImage={!item?.packshots ? true : false}
				isStudioAccess={props.isStudioAccess}
				index={index}
			>
				{props?.customListId ? (
					<CardPackshot
						key={`short-clips-${item?.productId}`}
						productId={item?.productId}
						isLandscape={true}
						title={item?.title}
						image={item?.imageUrl}
						vamId={item?.uniqueContentId}
						isProgressBar={props.isProgressBar}
						isStudioAccess={props.isStudioAccess}
						isAssignToScreen={props.isAssignToScreen}
						streamProgress={item?.streamProgress}
						isPreview={props?.isPreview}
						isHome={props?.isHome}
					/>
				) : (
					<CardPackshot
						key={`products-${item?.parentProductId}`}
						productId={item?.parentProductId}
						image={item?.packshots}
						backupImage={item?.backupPackshot}
						isLandscape={props.isLandscape}
						isStudioAccess={props.isStudioAccess}
						isProgressBar={props.isProgressBar}
						streamProgress={item?.streamProgress}
						isPreview={props?.isPreview}
						isHome={props?.isHome}
					/>
				)}
			</StyledSlider>
		);
	};

	const renderSeeMore = (
		<>
			{props.isSeeMore && props?.products?.length >= props.seeMoreLength ? (
				//@ts-ignore
				<StyledSlider key={`see-more-${props.id}`} isLandscape={props.isLandscape} isStudioAccess={props.isStudioAccess}>
					<SeeMoreComponent
						key={`see-more-button-${props.id}`}
						onPress={() => onPressSeeMore(props.id)}
						size={14}
						isLandscape={props.isLandscape}
					/>
				</StyledSlider>
			) : (
				<></>
			)}
		</>
	);

	const renderPlaylist = useMemo(
		() => (
			//@ts-ignore
			<DraggableScrollView>
				<FlatList
					data={props?.products}
					renderItem={renderItem}
					horizontal={true}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
					removeClippedSubviews={true}
					initialNumToRender={props.isLandscape || props.isStudioAccess ? 4 : 6} // Reduce initial render amount
					maxToRenderPerBatch={props.isLandscape || props.isStudioAccess ? 2 : 3}
					updateCellsBatchingPeriod={props.isLandscape || props.isStudioAccess ? 2 : 3}
					windowSize={props.isLandscape || props.isStudioAccess ? 2 : 3}
					ListFooterComponent={renderSeeMore}
					keyExtractor={(item) => item?.parentProductId ?? item?.id}
				/>
			</DraggableScrollView>
		),
		[props?.products, props.isLandscape, props.isStudioAccess, props.isAssignToScreen]
	);

	return <View style={{ flex: 1, flexDirection: "row", height: "300px" }}>{renderPlaylist}</View>;
};

export default PlaylistSlider;
